/* Classe principale */
.cookie-bar {
  background-color: #fafafa;
  bottom: 0;
  box-sizing: border-box;
  color: #616161;
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  height: 100%;
  left: 0;
  line-height: 1.3;
  max-height: none;
  overflow-y: auto;
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 999;
  @media (min-width: 768px) {
    font-size: 16px;
    height: auto;
    max-height: 90%;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  @media screen and (min-width: 992px) {
    padding: 16px 64px;
  }
}

/**
 * Fine classe principale
 */

.banner-container {
  margin: 0 auto;
  max-width: 1280px;
}

.cookie-bar__close {
  cursor: pointer;
  height: 14px;
  position: absolute;
  right: 0;
  top: 0;
  width: 14px;

  img {
    width: 100%;
  }

  &.button:hover,
  &.button:focus,
  &.button:active {
    background-color: transparent;
  }
}

/**
 * inizio view iniziale
 */

@media (min-width: 768px) {
  .cookie-bar__logo-container {
    display: flex;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 992px) {
  .cookie-bar__presentation {
    display: flex;
  }
}

.cookie-bar__presentation__titles {
  flex: 0 0 64%;
  margin-bottom: 16px;
  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 8px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}

.cookie-bar a {
  color: #0097b5;
  text-decoration: none;
}

.cookie-bar__presentation__buttons {
  flex: 0 0 36%;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }
  @media (min-width: 992px) {
    display: block;
    margin-left: 24px;
  }
}

.cookie-bar__presentation__buttons div:first-child {
  margin: 0;
  margin-bottom: 24px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 0;
  }
}

.cookie-bar__button {
  background-color: #0097b5;
  border: 2px solid #0097b5;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 24px;
  text-align: center;
}

.cookie-bar__button--outlined {
  background-color: #fff;
  color: #0097b5;
}

/**
 * Fine view iniziale
 */

/**
 * View delle opzioni
 */

.cookie-bar__options__head {
  padding-top: 32px;
}

.cookie-bar__options__head__header-desktop {
  margin-bottom: 24px;
}

.cookie-bar h3,
.cookie-bar h4 {
  color: #424242;
  text-transform: none;
}

// Sezione pulsanti cookie center desktop
.cookie-bar__options__head__buttons-desktop div,
.cookie-bar__options__head h3 {
  display: inline;
}

.cookie-bar__options__head__buttons-desktop {
  display: flex;
  float: right;
}

.cookie-bar__options__head__buttons-desktop div:last-child {
  margin-left: 24px;
}

// Sezione pulsanti cookie center mobile

.cookie-bar__options__head__buttons-small {
  margin: 0 0 24px;
  @media (min-width: 768px) {
    margin: 0 -8px 24px;
  }
}

.cookie-bar__options__head__buttons-small div:first-child,
.cookie-bar__options__head p {
  margin-bottom: 8px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .cookie-bar__presentation__buttons div,
  .cookie-bar__options__head__buttons-small div {
    flex-grow: 1;
    margin: 0 8px;
  }
}

// Fine sezione pulsanti

// Sezione options

.cookie-bar,
.cookie-bar__options__options-box {
  border-top: 1px solid #e0e0e0;
}

.cookie-bar__options__options-box {
  background-color: #f5f5f5;
  margin: 0 -16px 64px;
  max-height: none;
  // max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    height: calc(100vh - 450px);
    margin: 0 -64px;
    max-height: 450px;
  }
}

.cookie-bar__options__options-box__item {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 16px;

  p {
    margin-bottom: 16px;
  }
  @media screen and (min-width: 768px) {
    padding: 0 64px;
  }
}

.cookie-bar__options__options-box__item__input-container {
  margin: 16px 0;
}

/* Switch */

.cookie-bar__switch {
  margin-top: 16px;
  @media (min-width: 768px) {
    float: right;
    margin-top: 0;
  }
}

.cookie-bar__switch label {
  display: block;
  height: 20px;
  width: 40px;
}

.cookie-bar__slider {
  background-color: #0097b5;
  bottom: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;

  &,
  &::before {
    position: absolute;
    transition: 0.5s;
  }

  &::before {
    background-color: #fff;
    bottom: 2px;
    content: '';
    left: 2px;
    top: auto;
  }
}

.cookie-bar__switch label input:checked + .cookie-bar__slider {
  background-color: #424242;

  &::before {
    transform: translateX(20px);
  }
}

.cookie-bar__switch input:checked + .cookie-bar__slider::after,
.cookie-bar__switch input + .cookie-bar__slider::after {
  bottom: 0;
  color: #9e9e9e;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  left: 56px;
  opacity: 1;
  position: absolute;
  width: auto;
}

.cookie-bar__switch label input + .cookie-bar__slider::after {
  content: 'Disattivati';
  transform: none;
}

.cookie-bar__switch label input:checked + .cookie-bar__slider::after {
  content: 'Attivati';
}

@media screen and (min-width: 768px) {
  .cookie-bar__switch label input:checked + .cookie-bar__slider::after,
  .cookie-bar__switch label input + .cookie-bar__slider::after {
    left: auto;
    right: 56px;
  }
}

.cookie-bar__switch label .cookie-bar__slider.round,
.cookie-bar__switch label .cookie-bar__slider.round::before {
  border-radius: 16px;
}

.cookie-bar__switch label input {
  display: none;
}

.cookie-bar__switch label .cookie-bar__slider.round::before {
  height: 16px;
  width: 16px;
}

// Fine switch

.cookie-bar.show-options {
  max-height: 90%;
}

.cookie-bar h4 {
  line-height: 26px;
}

.cookie-bar__logo {
  margin-bottom: 24px;
  width: 60%;
}

@media screen and (min-width: 768px) {
  .cookie-bar__logo {
    width: 300px;
  }
}

.cookie-bar__presentation__titles h4 {
  margin-bottom: 12px;
  margin-top: 0;
}

.cookie-bar__presentation__buttons label {
  color: #0097b5;
}

/* Finestra con le opzioni */

@media screen and (min-width: 768px) {
  .cookie-bar__options__head p:nth-child(3) {
    margin-bottom: 24px;
  }
}

/* Box opzioni cookie */

.cookie-bar__options__options-box__item__required {
  color: #424242;
  font-size: 18px;
  font-style: italic;
}

@media screen and (min-width: 768px) {
  .cookie-bar__options__options-box__item span {
    float: right;
  }

  .cookie-bar__options__options-box__item h4 {
    display: inline;
  }
}

/* Cookie bar footer  */

.cookie-bar__options__footer {
  margin-top: 16px;
}

.cookie-bar__options__footer .cookie-bar__button {
  float: right;
  padding: 8px 32px;
}

@media screen and (min-width: 768px) {
  .cookie-bar__options__footer .cookie-bar__button {
    padding: 8px 128px;
  }
}

/* Helpers */

@media screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hide-on-desktop {
    display: none;
  }
}

.cookiebar-text-semibold {
  font-weight: 500;
}

.cookiebar-text-bold {
  font-weight: 600;
}

.cookiebar-text-size-1 {
  font-size: 32px;
}

.cookiebar-text-size-2 {
  font-size: 20px;
}

/* Fine helpers */
@media (max-width: 767px) {
  .cookie-bar.show-options {
    height: 100%;
    max-height: none;
  }

  .cookie-bar__options__footer {
    background: #fafafa;
    bottom: 0;
    height: 80px;
    left: 0;
    padding: 16px 0;
    position: fixed;
    width: 100%;
  }

  .cookie-bar__options__footer .cookie-bar__button {
    margin-right: 16px;
  }
}

.cookie-bar__logo-container img {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .cookie-bar__logo-container img {
    margin-right: 16px;
    max-height: 24px;
    width: auto;
  }

  .cookie-bar .cookie-bar__logo-container h4 {
    font-size: 16px;
    margin: 0;
  }

  .cookie-bar.show-options {
    padding-top: 32px;
  }
}

@media (min-width: 992px) {
  .cookie-bar.show-options {
    padding: 32px 64px 16px;
  }
}

.cookie-bar.show-options .cookie-bar__logo-container h4 {
  display: none;
}

.cookie-bar.show-options .cookie-bar__logo-container img {
  margin-bottom: 24px;
  max-height: none;
}

@media (max-width: 767px) and (max-device-aspect-ratio: 50/100) {
  .cookie-bar-half-mobile.cookie-body-background {
    bottom: 0;
    height: auto;
    top: auto;
  }

  .cookie-bar-half-mobile .cookie-bar__presentation__titles p {
    font-size: 12px;
  }

  .cookie-bar-half-mobile .cookie-bar__logo {
    height: 24px;
    margin-bottom: 0;
    margin-top: 0;
    width: auto;
  }

  .cookie-bar-half-mobile .cookie-bar__presentation__titles,
  .cookie-bar-half-mobile .cookie-bar__presentation__titles h4,
  .cookie-bar-half-mobile .cookie-bar__presentation__buttons div:first-child {
    margin-bottom: 8px;
  }

  .cookie-bar-half-mobile .cookie-bar__logo-container h4 {
    line-height: 1;
    margin: 0 0 0.5rem;
  }

  .cookie-bar-half-mobile {
    bottom: 0;
    height: auto;
    top: auto;
  }

  .cookie-bar-half-mobile .cookie-bar {
    padding: 8px 16px;
    position: relative;
  }

  .cookie-bar-half-mobile .cookie-bar__options__options-box {
    max-height: 40vh;
  }

  .cookie-bar.show-options {
    height: auto;
  }
}
