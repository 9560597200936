$tabs-toggle-link-radius: 0

// Colors
$black-bis: #212121
$white-bis: #e2e2e2
$white-ter: #eeeeee
$grey-dark: #424242
$info: #FCD601
$primary: #052054
// Typography
$family-sans-serif: "Titillium Web", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif
$family-secondary: "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif
$size-1: 45px
$size-3: 35px
$size-6: 0.875rem