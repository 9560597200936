.link-button {
  @extend a;
  appearance: none;
  background: none;
  border: 0;
  color: $link;
  padding: 0;

  &:focus {
    outline: none;
  }
}
